import React, { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import {
  BusinessUnitFilter,
  ClientFilter,
  ClientRecordStatusFilter,
  ClientTagFilter,
  FiltersBar,
  IndustryFilter,
  InlineTooltip,
  LinkButton,
  LocationFilter,
  MemberFilter,
  Page,
  PracticeFilter,
  SearchInput,
  SingleSelect,
} from '~/components';
import { useAuth, useFeatures } from '~/hooks';
import _ from 'lodash';
import ExportDropdown from '../settings/ExportDropdown.jsx';
import Filter from '~/components/filters/Filter.js';
import FilterButton from '../reports/components/FilterButton.jsx';
import FiltersDrawer from '../reports/components/FiltersDrawer.jsx';
import FiltersGroup from '../reports/components/FiltersGroup.jsx';
import mimeTypes from '~/utils/mimeTypes.js';

export default function ClientsListFilters({ onChange, onExport, params }) {
  const auth = useAuth();

  const { url } = useRouteMatch();
  const location = useLocation();
  const features = useFeatures();

  const handleChange = ({ target: { name, value } }) => {
    onChange({ [name]: value });
  };

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);

  return (
    <>
      <Page.Header>
        <Page.Title>Clients</Page.Title>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`clients.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`clients.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />

          <LinkButton to={`${url}/new${location.search}`} disabled={!auth.clients.create} className="button">
            Create a Client
            {!auth.clients.create && (
              <InlineTooltip message="Your security role prohibits you from creating clients." />
            )}
          </LinkButton>
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <SearchInput
            placeholder="All"
            materialPlaceholder="Search"
            materialAlwaysVisible
            onChange={handleChange}
            value={params.q}
          />

          <SingleSelect
            name="recordStatusId"
            placeholder="All"
            materialPlaceholder="Client Archived"
            materialAlwaysVisible
            value={params.recordStatusId}
            showEmptyOption
            onChange={handleChange}>
            <option value="active">No</option>
            <option value="archived">Yes</option>
          </SingleSelect>

          <MemberFilter
            name="owners"
            placeholder="All"
            materialPlaceholder="Client Relationship Owner"
            value={params.owners}
            onChange={handleChange}
          />

          {features.practices && (
            <PracticeFilter
              name="practices"
              materialPlaceholder="Client Practice"
              value={params.practices}
              onChange={handleChange}
            />
          )}

          <ClientTagFilter name="tags" value={params.tags} onChange={handleChange} />

          <ClientFiltersBar filters={params} onChange={onChange} />
        </FiltersBar>

        <Filters values={params} isOpen={filtersVisible} onApply={onChange} onClose={hideFilters} />
      </Page.Filters>
    </>
  );
}

function ClientFiltersBar({ filters, onChange }) {
  return (
    <>
      {!_.isEmpty(filters.clients) && (
        <ClientFilter value={filters.clients} onChange={({ value }) => onChange({ clients: value })} />
      )}

      {!_.isEmpty(filters.clientBusinessUnits) && (
        <BusinessUnitFilter
          materialPlaceholder="Client Business Unit"
          value={filters.clientBusinessUnits}
          onChange={({ value }) => onChange({ clientBusinessUnits: value })}
        />
      )}

      {!_.isEmpty(filters.clientSalesRepresentatives) && (
        <MemberFilter
          materialPlaceholder="Client Sales Representative"
          value={filters.clientSalesRepresentatives}
          onChange={({ value }) => onChange({ clientSalesRepresentatives: value })}
        />
      )}

      {!_.isEmpty(filters.clientLocations) && (
        <LocationFilter
          materialPlaceholder="Client Location"
          value={filters.clientLocations}
          onChange={({ value }) => onChange({ clientLocations: value })}
        />
      )}

      {!_.isEmpty(filters.clientIndustries) && (
        <IndustryFilter
          materialPlaceholder="Client Industry"
          value={filters.clientIndustries}
          onChange={({ value }) => onChange({ clientIndustries: value })}
        />
      )}
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
    onClose();
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ClientFiltersGroup filters={filters} onChange={handleFilter} />
    </FiltersDrawer>
  );
}

function ClientFiltersGroup({ filters, onChange }) {
  const features = useFeatures();

  return (
    <FiltersGroup
      label="Client Filters"
      filters={[
        filters.clients,
        filters.recordStatusId,
        filters.practices,
        filters.clientBusinessUnits,
        filters.clientSalesRepresentatives,
        filters.owners,
        filters.tags,
        filters.clientLocations,
        filters.clientIndustries,
      ]}>
      <Filter>
        <ClientFilter
          name="clients"
          value={filters.clients}
          onChange={({ target: { value } }) => onChange({ clients: value })}
        />
      </Filter>

      <Filter>
        <ClientRecordStatusFilter
          value={filters.recordStatusId}
          onChange={({ target: { value } }) => onChange({ recordStatusId: value })}
        />
      </Filter>

      {features.practices && (
        <Filter>
          <PracticeFilter
            value={filters.practices}
            materialPlaceholder="Client Practice"
            onChange={({ target: { value } }) => onChange({ practices: value })}
          />
        </Filter>
      )}

      {features.businessUnits && (
        <Filter>
          <BusinessUnitFilter
            value={filters.clientBusinessUnits}
            materialPlaceholder="Client Business Unit"
            onChange={({ target: { value } }) => onChange({ clientBusinessUnits: value })}
          />
        </Filter>
      )}

      <Filter>
        <MemberFilter
          value={filters.clientSalesRepresentatives}
          materialPlaceholder="Client Sales Representative"
          onChange={({ target: { value } }) => onChange({ clientSalesRepresentatives: value })}
        />
      </Filter>

      <Filter>
        <MemberFilter
          value={filters.owners}
          materialPlaceholder="Client Relationship Owner"
          onChange={({ target: { value } }) => onChange({ owners: value })}
        />
      </Filter>

      <Filter>
        <ClientTagFilter value={filters.tags} onChange={({ target: { value } }) => onChange({ tags: value })} />
      </Filter>

      <Filter>
        <LocationFilter
          value={filters.clientLocations}
          materialPlaceholder="Client Location"
          onChange={({ target: { value } }) => onChange({ clientLocations: value })}
        />
      </Filter>

      <Filter>
        <IndustryFilter
          value={filters.clientIndustries}
          materialPlaceholder="Client Industry"
          onChange={({ target: { value } }) => onChange({ clientIndustries: value })}
        />
      </Filter>
    </FiltersGroup>
  );
}
