import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useApi, useWorkspace } from '~/contexts';
import MultiSelect from '../MultiSelect';
import Fuse from 'fuse.js';
import Percent from '../Percent';
import opportunityStageCategories from '~/lookups/opportunity-stage-categories';

export default function OpportunityStageFilter({
  materialPlaceholder = 'Opportunity Stage',
  placeholder = 'All',
  name,
  value,
  onChange,
  opportunityStageStatusId,
}) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [data, setData] = useState(null);
  const [options, setOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      let options = data;
      if (!data) {
        const result = await api.www
          .workspaces(workspace.id)
          .opportunityStages()
          .get({ q, isActive: true, statusId: opportunityStageStatusId ? opportunityStageStatusId : undefined });
        setData(result.data);
        options = result.data;
      }
      if (q) {
        options = new Fuse(options, { keys: ['name'], threshold: 0.4 }).search(q).map((result) => result.item);
      }
      setOptions(options);
    },
    [workspace.id, api, data, opportunityStageStatusId],
  );

  useEffect(() => {
    setData(null);
    setOptions([]);
  }, [opportunityStageStatusId]);

  const groups = useMemo(() => {
    if (!options) return null;
    return _(options)
      .orderBy((o) => {
        switch (o.statusId) {
          case 'open':
            return 0;
          case 'won':
            return 1;
          case 'lost':
            return 2;
        }
      })
      .groupBy((o) => o.statusId)
      .value();
  }, [options]);

  return (
    <MultiSelect.Input
      materialAlwaysVisible
      materialPlaceholder={materialPlaceholder}
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.map((v) => v.name).join('; ')}
      options={options}
      renderOptions={(opts) => {
        const optionsById = _.keyBy(opts, 'id');
        return Object.keys(groups).map((status) => (
          <MultiSelect.Group key={status} label={opportunityStageCategories[status].name}>
            {groups[status].map((option) => (
              <MultiSelect.Option key={option.id} inactive={!option.isActive} {...optionsById[option.id].props}>
                {option.name} (
                <Percent value={option.probability / 100} minimumFractionDigits={0} maximumFractionDigits={0} />)
              </MultiSelect.Option>
            ))}
          </MultiSelect.Group>
        ));
      }}
      isLoading={!data}
      onSearch={handleSearch}
      onChange={onChange}
    />
  );
}
