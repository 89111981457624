import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { ExportDialog, Page } from '~/components';
import { useApi, useConfirmation, useSubscription, useWorkspace } from '~/contexts';
import {
  useClientFilters,
  useDocumentTitle,
  useIsMounted,
  useProjectFilters,
  useSearchParams,
  useSearchParamsConfig,
} from '~/hooks';
import EditTimeEntry from '~/routes/app/time/edit-time-entry';
import { PageLoader } from '~/routes/public/pages';
import { QuerySort, dateFormats } from '~/utils';
import useReportsSearchParamsConfig from '../reports/hooks/useReportsSearchParamsConfig.js';
import ProjectDeleteConfirmation from './ProjectDeleteConfirmation';
import ProjectsListFilters from './ProjectsListFilters';
import ProjectsTable from './ProjectsTable';
import ProjectDrawer from './project-drawer/ProjectDrawer';

export default function ProjectsListPage({ clientId, memberId, mode = 'page', renderFilters, sessionKey }) {
  const documentTitle = useDocumentTitle();
  const { url } = useRouteMatch();
  useEffect(() => {
    if (mode === 'page') documentTitle.set('Projects');
  }, [mode, documentTitle]);

  const { workspace } = useWorkspace();
  const api = useApi();

  const isMounted = useIsMounted();

  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const history = useHistory();
  const { clientKey, projectKey } = useParams();
  const location = useLocation();

  const [timeEntryDrawer, setTimeEntryDrawer] = useState(null);
  const { notify } = useSubscription();

  const [query, setQuery] = useState({
    isReady: false,
    searchParamsStatus: 'pending',
    data: null,
    params: {
      q: '',
      practices: [],
      statuses: [],
      clients: [],
      sort: new QuerySort('name', 'asc'),
      page: 0,
      size: 25,
      withGraph: 'administrators,budget,tags',
      recordStatusId: 'active',
      billingTypeIds: [],
      tags: [],
      administrators: [],
      startedDatePeriod: null,
      endedDatePeriod: null,
      createdAtPeriod: null,
      completedOnPeriod: null,
      projectBudgetHealth: [],
      projectScheduleHealth: [],
      projectClientSatisfaction: [],
      projectTeamSatisfaction: [],
      ...clientFilters.filters,
      ...projectFilters.filters,
    },
    action: 'load',
  });

  const setParams = (params) => {
    setQuery((state) => ({
      ...state,
      action: 'filter',
      params: { ...state.params, ...params, page: 0 },
      searchParamsStatus: 'ready',
    }));
  };

  const loadMore = useCallback(() => {
    setQuery((state) => {
      if (
        state.searchParamsStatus !== 'ready' ||
        state.action !== null ||
        !state.data ||
        state.data.total <= state.data.results.length
      ) {
        return state;
      }

      return {
        ...state,
        params: { ...state.params, page: state.params.page + 1 },
        action: 'load-more',
      };
    });
  }, []);

  const removeItem = (id) => {
    setQuery((state) => ({
      ...state,
      data: {
        ...state.data,
        results: state.data?.results.filter((i) => i.id !== id),
        total: state.data.total - 1,
      },
    }));
  };

  const updateItem = (item) => {
    setQuery((state) => ({
      ...state,
      data: {
        ...state.data,
        results: state.data?.results.map((i) => (i.id === item.id ? { ...i, ...item } : i)),
      },
    }));
  };

  const { action, data, isReady, params, searchParamsStatus } = query;

  const confirmation = useConfirmation();

  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: '' },
        practices: searchParamsConfig.practices,
        clients: searchParamsConfig.clients,
        statuses: searchParamsConfig.projectStatuses,
        recordStatusId: { default: 'active', ...searchParamsConfig.recordStatusId },
        billingTypeIds: searchParamsConfig.projectBillingTypes,
        tags: searchParamsConfig.projectTags,
        administrators: searchParamsConfig.members,
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        startedDatePeriod: reportsSearchParamsConfig.startPeriod,
        endedDatePeriod: reportsSearchParamsConfig.endPeriod,
        createdAtPeriod: reportsSearchParamsConfig.createdPeriod,
        completedOnPeriod: reportsSearchParamsConfig.completedPeriod,
        projectBudgetHealth: searchParamsConfig.projectBudgetHealth,
        projectScheduleHealth: searchParamsConfig.projectScheduleHealth,
        projectClientSatisfaction: searchParamsConfig.projectClientSatisfaction,
        projectTeamSatisfaction: searchParamsConfig.projectTeamSatisfaction,
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [reportsSearchParamsConfig, searchParamsConfig, clientFilters, projectFilters],
    ),

    sessionKey,

    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  const urlSearchParams = useMemo(
    () => ({
      ..._.omit(
        params,
        'clients',
        'clientIndustries',
        'clientLocations',
        'clientOwners',
        'clientPractices',
        'clientSalesRepresentatives',
        'clientTags',
        'administrators',
        'billingTypeIds',
        'practices',
        'projects',
        'projectBudgetHealth',
        'projectClientSatisfaction',
        'projectScheduleHealth',
        'projectTeamSatisfaction',
        'projectSalesRepresentatives',
        'projectTypes',
        'statuses',
        'tags',
        'startedDatePeriod',
        'endedDatePeriod',
        'createdAtPeriod',
        'completedOnPeriod',
      ),
      practiceId: params.practices?.map((practice) => practice.id),
      tagIds: params.tags?.map((tag) => tag.id),
      clientId: params.clients.map((client) => client.id),
      status: params.statuses?.map((status) => status.id),
      billingTypeId: params.billingTypeIds?.map((billingType) => billingType.id),
      recordStatusId: params.recordStatusId ?? undefined,
      administratorId: params.administrators?.map((admin) => admin.id),
      memberId,
      startFrom: params.startedDatePeriod?.start ?? undefined,
      startTo: params.startedDatePeriod?.end ?? undefined,
      endFrom: params.endedDatePeriod?.start ?? undefined,
      endTo: params.endedDatePeriod?.end ?? undefined,
      createdStart: params.createdAtPeriod?.start ?? undefined,
      createdEnd: params.createdAtPeriod?.end ?? undefined,
      completedOnFrom: params.completedOnPeriod?.start ?? undefined,
      completedOnTo: params.completedOnPeriod?.end ?? undefined,
      projectBudgetHealthId: params.projectBudgetHealth?.map((v) => v.id),
      projectScheduleHealthId: params.projectScheduleHealth?.map((v) => v.id),
      projectClientSatisfactionId: params.projectClientSatisfaction?.map((v) => v.id),
      projectTeamSatisfactionId: params.projectTeamSatisfaction?.map((v) => v.id),
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters, memberId],
  );

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) setParams(params);
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    try {
      const params = {
        ...urlSearchParams,
        clientId: urlSearchParams.clientId.length ? urlSearchParams.clientId : clientId,
      };

      const { data } = await api.www.workspaces(workspace.id).projects().get(params);

      if (!isMounted.current) return;

      setQuery((state) => ({
        ...state,
        action: null,
        searchParamsStatus: 'ready',
        data: {
          ...data,
          results: state.action === 'load-more' ? [...state.data.results, ...data.results] : data.results,
          total: data.total,
        },
      }));
    } catch (error) {
      setQuery((state) => ({ ...state, data: { total: 0, results: [] } }));
    }
  }, [workspace.id, api, isMounted, urlSearchParams, clientId]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleFilter = (values) => {
    setParams({ ...values });
    searchParams.set({ ..._.omit(values, 'sort') });
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ sort: querySort });
    searchParams.set({ sort: querySort });
  };

  async function handleSaved(project) {
    const { data } = await api.www
      .workspaces(workspace.id)
      .projects()
      .get({ ids: project.id, withGraph: 'administrators,budget' });
    updateItem(data[0]);
  }

  async function handleDeleted(project) {
    removeItem(project.id);
  }

  function handleClose() {
    history.push({ pathname: `/app/${workspace.key}/projects`, search: location.search }, { scrollToTop: false });
    documentTitle.set('Projects');
  }

  async function handleDeleteConfirmation(project) {
    confirmation.prompt((resolve) => (
      <ProjectDeleteConfirmation
        project={project}
        onClose={resolve}
        onDelete={() => {
          handleDeleted(project);
          resolve(true);
        }}
      />
    ));
  }

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .projects()
          .export(urlSearchParams, {
            headers: { accept: mimeType },
            responseType: 'blob',
          })}
        onClose={resolve}
      />
    ));
  };

  const Container = useCallback(
    (props) => (mode === 'page' ? <Page scrollable {...props} /> : <React.Fragment {...props} />),
    [mode],
  );

  if (!isReady && !data) return <PageLoader />;

  const isEdit = clientKey && projectKey && url === `/app/${workspace.key}/projects/edit/${clientKey}/${projectKey}`;

  return (
    <>
      <Container>
        {renderFilters ? (
          renderFilters({ query, onFilter: handleFilter })
        ) : (
          <ProjectsListFilters onChange={handleFilter} onExport={handleExport} params={params} />
        )}

        <Page.ListView>
          <ProjectsTable
            data={data}
            params={params}
            mode={mode}
            action={action}
            onRowClick={({ key, client }) => history.push(`/app/${workspace.key}/projects/${client.key}/${key}`)}
            onEdit={(project) =>
              history.push({
                pathname: `${url}/edit/${project.client.key}/${project.key}`,
                search: location.search,
                state: { scrollToTop: false },
              })
            }
            onClone={(project) =>
              history.push({
                pathname: `${url}/clone/${project.client.key}/${project.key}`,
                search: location.search,
                state: { scrollToTop: false },
              })
            }
            onDelete={handleDeleteConfirmation}
            onSaved={handleSaved}
            onSort={handleSort}
            onDrawerClose={() => documentTitle.set('Projects')}
            onTrackTime={(value) => setTimeEntryDrawer(value)}
            onLoadMore={loadMore}
          />
        </Page.ListView>
      </Container>

      {isEdit && (
        <ProjectDrawer
          clientKey={clientKey}
          projectKey={projectKey}
          onSaved={handleSaved}
          onDeleted={handleDeleted}
          onClose={handleClose}
        />
      )}
      {timeEntryDrawer && (
        <EditTimeEntry
          initialValues={{ projectId: timeEntryDrawer.project.id, date: moment().format(dateFormats.isoDate) }}
          onClose={() => {
            setTimeEntryDrawer(null);
            documentTitle.set('Projects');
          }}
          onSaved={(timeEntry) => {
            notify(useSubscription.keys.refresh_timer);
            handleSaved(timeEntry.project);
          }}
        />
      )}
    </>
  );
}
