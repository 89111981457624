import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Avatar,
  BackLink,
  Currency,
  DateTime,
  Icon,
  MemberContactPopover,
  OpportunityLink,
  Percent,
  SingleSelect,
  Tag,
  Tooltip,
} from '~/components';
import CompanyLink from '~/components/CompanyLink';
import { Filter } from '~/components/filters';
import OpportunityTagFilter from '~/components/filters/OpportunityTagFilter';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useIsMounted, useSearchParams, useSearchParamsConfig } from '~/hooks';
import PageLoader from '~/routes/public/pages/PageLoader';
import { colors, weights } from '~/styles';
import { QuerySort, mimeTypes } from '~/utils';
import ClosedPeriodReportFilter from '../components/ClosedPeriodReportFilter';
import CreatedPeriodReportFilter from '../components/CreatedPeriodReportFilter';
import CurrencyFilter from '../components/CurrencyFilter';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import FilterButton from '../components/FilterButton';
import PeriodNavFilter from '../components/PeriodNavFilter';
import Report from '../components/Report';
import Table from '../components/table';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import opportunityStageCategories from '~/lookups/opportunity-stage-categories';

const Byline = styled.small`
  display: block;
`;

const InfoContainer = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const Owner = styled.div`
  display: flex;
  align-items: center;
`;

const OwnerInfo = styled.span`
  margin-left: 0.5rem;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TagsTitle = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

function FirstAssignment({ tags }) {
  const firstTag = tags[0];
  if (!firstTag) return null;

  return (
    <Tag style={{ backgroundColor: colors.grey5 }}>
      <small>{firstTag.name}</small>
    </Tag>
  );
}

function Assignments({ tags }) {
  const count = tags.length - 1;
  if (count <= 0) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <TagsTitle>Tags</TagsTitle>
          {tags.map((tag) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={tag.id}>
              <small>{tag.name}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{count}</small>
      </Tag>
    </Tooltip>
  );
}

function OpportunityDetail() {
  useDocumentTitle('Opportunity Detail');
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();
  const isMounted = useIsMounted();

  const [query, setQuery] = useState({ report: null, status: 'loading', loading: { table: false } });
  const [params, setParams] = useState({
    period: null,
    closedPeriod: null,
    sort: new QuerySort('closeDate', 'asc'),
    opportunityStageStatusId: 'open',
    tags: [],
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        createdPeriod: reportsSearchParamsConfig.createdPeriod,
        closedPeriod: reportsSearchParamsConfig.closedPeriod,
        currency: searchParamsConfig.currency,
        opportunityStageStatusId: {
          default: 'open',
          valid: ['open', 'won', 'lost', 'all'],
          key: 'opportunityStageStatusId',
          serialize: (value) => ({ opportunityStageStatusId: value ? value : 'all' }),
          deserialize: (value) => (value === 'all' ? '' : value),
        },
        tags: searchParamsConfig.opportunityTags,
        sort: { default: new QuerySort('closeDate', 'asc'), ...searchParamsConfig.sort },
      }),
      [searchParamsConfig, reportsSearchParamsConfig],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      createdStart: params.createdPeriod?.start ?? undefined,
      createdEnd: params.createdPeriod?.end ?? undefined,
      closedStart: params.closedPeriod?.start ?? undefined,
      closedEnd: params.closedPeriod?.end ?? undefined,
      opportunityStageStatusId: params.opportunityStageStatusId ?? undefined,
      opportunityTagId: params.tags.length ? params.tags.map((v) => v.id) : [],
      currency: params.currency ?? undefined,
      sort: params.sort,
    }),
    [params],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().opportunityDetail(urlSearchParams);
    if (!isMounted.current) return;

    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: {
        table: false,
      },
    }));
  }, [api, workspace.id, urlSearchParams, isMounted]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .opportunityDetail(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/pipeline`} />

        <Report.Info>
          <Report.Eyebrow>Pipeline Reports</Report.Eyebrow>
          <Report.Title>Opportunity Detail</Report.Title>
        </Report.Info>

        <Report.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        {(params.createdPeriod?.start || params.createdPeriod?.end) && (
          <PeriodNavFilter
            materialPlaceholder="Created Date Range"
            value={params.createdPeriod}
            onChange={(value) => handleApplyFilters({ createdPeriod: value })}
          />
        )}

        {(params.closedPeriod?.start || params.closedPeriod?.end) && (
          <PeriodNavFilter
            materialPlaceholder="Expected Closed Date Range"
            value={params.closedPeriod}
            onChange={(value) => handleApplyFilters({ closedPeriod: value })}
          />
        )}

        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}

        {!_.isEmpty(params.opportunityStageStatusId) && (
          <SingleSelect
            name="opportunityStageStatusId"
            placeholder="All"
            showEmptyOption
            materialPlaceholder="Stage Category"
            materialAlwaysVisible
            value={params.opportunityStageStatusId}
            onChange={({ target: { value } }) => handleApplyFilters({ opportunityStageStatusId: value })}>
            <option value="open">Open</option>
            <option value="won">Won</option>
            <option value="lost">Lost</option>
          </SingleSelect>
        )}

        {!_.isEmpty(params.tags) && (
          <OpportunityTagFilter
            name="tags"
            placeholder="All"
            materialPlaceholder="Opportunity Tags"
            materialAlwaysVisible
            value={params.tags}
            onChange={({ target: { value } }) => handleApplyFilters({ tags: value })}
          />
        )}
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;

  return (
    <>
      <Report.Status>
        {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
        <Table.Total label="Opportunity" value={report.records.length} />
      </Report.Status>

      <Table>
        <Table.Header sticky>
          <Table.Column sticky name="opportunity.name" minWidth="16rem" onSort={onSort} sort={params.sort}>
            Opportunity/Company
          </Table.Column>
          <Table.Column name="owner.name" minWidth="16rem" onSort={onSort} sort={params.sort}>
            Owner
          </Table.Column>
          <Table.Column minWidth="10rem">Tags</Table.Column>
          <Table.Column name="opportunityStage.name" onSort={onSort} sort={params.sort} width="10rem">
            Stage
          </Table.Column>
          <Table.Column name="createdAt" onSort={onSort} sort={params.sort} align="right" width="10rem">
            Created
          </Table.Column>
          <Table.Column name="closeDate" onSort={onSort} sort={params.sort} align="right" width="10rem">
            Closing
          </Table.Column>
          <Table.Column name="convertedAmount" onSort={onSort} sort={params.sort} align="right" width="10rem">
            Amount
          </Table.Column>
          <Table.Column name="probability" onSort={onSort} sort={params.sort} align="right" width="10rem">
            Probability
          </Table.Column>
          <Table.Column name="convertedWeightedAmount" onSort={onSort} sort={params.sort} align="right" width="10rem">
            Weighted
          </Table.Column>
        </Table.Header>

        <Table.Body>
          {report.records.map((opportunity) => (
            <Table.Row key={opportunity.id}>
              <Table.Cell>
                <InfoContainer>
                  <OpportunityLink opportunity={opportunity} />
                  <small>
                    <CompanyLink company={opportunity.company} />
                  </small>
                </InfoContainer>
              </Table.Cell>
              <Table.Cell>
                {opportunity.owner && (
                  <Owner>
                    <MemberContactPopover member={opportunity.owner} placement="left">
                      <Avatar value={opportunity.owner} isCircle hasBackground initialsFontSize=".9rem" />
                    </MemberContactPopover>
                    <OwnerInfo>{opportunity.owner.name}</OwnerInfo>
                  </Owner>
                )}
              </Table.Cell>
              <Table.Cell>
                <Tags>
                  <FirstAssignment tags={opportunity.tags} />
                  <Assignments tags={opportunity.tags} />
                </Tags>
              </Table.Cell>
              <Table.Cell>
                <p>
                  {`${opportunity.opportunityStage.name}`}
                  <Byline>{opportunityStageCategories[opportunity.opportunityStage.statusId].name}</Byline>
                </p>
              </Table.Cell>
              <Table.Cell>
                <DateTime value={opportunity.createdAt} />
              </Table.Cell>
              <Table.Cell>
                <DateTime value={opportunity.closeDate} />
              </Table.Cell>
              <Table.Cell>
                <p>
                  <Currency value={opportunity.convertedAmount} currency={report.currency} />
                  {opportunity.currency !== report.currency && (
                    <Byline>
                      <Currency value={opportunity.amount} currency={opportunity.currency} />
                    </Byline>
                  )}
                </p>
              </Table.Cell>
              <Table.Cell>
                <Percent value={opportunity.probability / 100} minimumFractionDigits={0} maximumFractionDigits={0} />
              </Table.Cell>
              <Table.Cell>
                <p>
                  <Currency value={opportunity.convertedWeightedAmount} currency={report.currency} />
                  {opportunity.currency !== report.currency && (
                    <Byline>
                      <Currency value={opportunity.weightedAmount} currency={opportunity.currency} />
                    </Byline>
                  )}
                </p>
              </Table.Cell>
            </Table.Row>
          ))}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.convertedAmount} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.convertedWeightedAmount} currency={report.currency} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <SingleSelect
          name="opportunityStageStatusId"
          placeholder="All"
          showEmptyOption
          materialPlaceholder="Stage Category"
          materialAlwaysVisible
          value={filters.opportunityStageStatusId}
          onChange={({ target: { value } }) => handleFilter({ opportunityStageStatusId: value })}>
          <option value="open">Open</option>
          <option value="won">Won</option>
          <option value="lost">Lost</option>
        </SingleSelect>
      </Filter>
      <CreatedPeriodReportFilter
        value={filters.createdPeriod}
        onChange={({ target: { value } }) => handleFilter({ createdPeriod: value })}
      />
      <ClosedPeriodReportFilter
        value={filters.closedPeriod}
        onChange={({ target: { value } }) => handleFilter({ closedPeriod: value })}
      />
      <Filter>
        <OpportunityTagFilter
          name="tags"
          placeholder="All"
          materialPlaceholder="Opportunity Tags"
          materialAlwaysVisible
          value={filters.tags}
          onChange={({ target: { value } }) => handleFilter({ tags: value })}
        />
      </Filter>
    </Report.FiltersDrawer>
  );
}

export default OpportunityDetail;
